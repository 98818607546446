var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vxe-toolbar", {
        scopedSlots: _vm._u([
          {
            key: "buttons",
            fn: function () {
              return [
                !_vm.disabled
                  ? _c(
                      "el-upload",
                      {
                        staticClass: "upload-table",
                        attrs: {
                          action: "/upload/uploadController/uploadOssList",
                          headers: {
                            loginUserToken: _vm.Storage.l.get("TokenKey"),
                          },
                          multiple: "",
                          "show-file-list": false,
                          "on-exceed": _vm.handleExceed,
                          "before-upload": _vm.beforeUpload,
                          "on-preview": _vm.handlePreview,
                          "on-remove": _vm.handleRemove,
                          "before-remove": _vm.beforeRemove,
                          "on-success": _vm.handleSuccess,
                          limit: _vm.limit,
                          accept: _vm.accept || "",
                          "file-list": _vm.fileList,
                        },
                      },
                      [_c("vxe-button", [_vm._v("上传附件")])],
                      1
                    )
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "vxe-table",
        { ref: "uploadTable", attrs: { data: _vm.value } },
        [
          _c("vxe-table-column", {
            attrs: { field: "objectName", title: "附件名称" },
          }),
          _c("vxe-table-column", {
            attrs: { title: "操作", width: "200", "show-overflow": "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.downLoad(row)
                          },
                        },
                      },
                      [_vm._v("下载")]
                    ),
                    _vm._v("    "),
                    !_vm.disabled
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleRemove(row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }