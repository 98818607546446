// import Form from '../../../../../../components/form';
// import processLog from './process_log.vue';

// export default {
//   name: 'processForm',
//   extends: Form,
//   components: {
//     processLog,
//   },
// };
import processLog from './process_log.vue';

export default processLog;
