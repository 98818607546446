import Register from './register';

import TextView from './text_view';
import Budget from './budget';
import RegisterButton from './register_button';
import Additional from './additional';
import Approve from './approve';
import ProcessForm from './processForm';

export default Register;

export {
  TextView, Budget, RegisterButton, Additional, Approve, ProcessForm,
};
