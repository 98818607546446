var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sub-table" },
    [
      !_vm.disabled
        ? _c("vxe-toolbar", {
            scopedSlots: _vm._u(
              [
                {
                  key: "buttons",
                  fn: function () {
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", icon: "el-icon-plus" },
                          on: { click: _vm.addTable },
                        },
                        [_vm._v("新增行")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticStyle: { color: "#f56c6c" },
                          attrs: { type: "text", icon: "el-icon-delete" },
                          on: { click: _vm.deleteTable },
                        },
                        [_vm._v("清空")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              3034557394
            ),
          })
        : _vm._e(),
      _c(
        "vxe-table",
        { ref: "xTable", attrs: { data: _vm.value, "max-height": "200" } },
        [
          _c("vxe-table-column", {
            attrs: { type: "seq", title: "行编号", width: "60" },
          }),
          _c("vxe-table-column", {
            attrs: {
              field: "categoriesName",
              title: "投入类型名称",
              "min-width": "120",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  var rowIndex = ref.rowIndex
                  return [
                    !_vm.disabled
                      ? _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: "请选择",
                              loading: _vm.loading,
                            },
                            on: {
                              focus: _vm.focusEvent,
                              change: function (v) {
                                return _vm.changeEvent(v, rowIndex)
                              },
                            },
                            model: {
                              value: row.categoriesName,
                              callback: function ($$v) {
                                _vm.$set(row, "categoriesName", $$v)
                              },
                              expression: "row.categoriesName",
                            },
                          },
                          _vm._l(_vm.tpmCostTypeList, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: {
                                label: item.categoriesName,
                                value: item.categoriesName,
                              },
                            })
                          }),
                          1
                        )
                      : _c("span", [_vm._v(_vm._s(row.categoriesName))]),
                  ]
                },
              },
            ]),
          }),
          _c("vxe-table-column", {
            attrs: {
              field: "categoriesCode",
              title: "投入类型编码",
              "min-width": "120",
            },
          }),
          _c("vxe-table-column", {
            attrs: {
              field: "ownedSalesCompanyName",
              title: "所属销售公司",
              "min-width": "120",
            },
          }),
          _c("vxe-table-column", {
            attrs: {
              field: "ownedCusCodesName",
              title: "所属客户",
              "min-width": "120",
            },
          }),
          _c("vxe-table-column", {
            attrs: { field: "payType", title: "支付类型", "min-width": "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  var rowIndex = ref.rowIndex
                  return [
                    !_vm.disabled
                      ? _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择" },
                            on: {
                              change: function (v) {
                                return _vm.payTypeChange(
                                  rowIndex,
                                  v,
                                  row.payTypes
                                )
                              },
                            },
                            model: {
                              value: row.payType,
                              callback: function ($$v) {
                                _vm.$set(row, "payType", $$v)
                              },
                              expression: "row.payType",
                            },
                          },
                          _vm._l(row.payTypes, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: {
                                label: item.dictKey,
                                value: item.dictValue,
                              },
                            })
                          }),
                          1
                        )
                      : _c("span", [
                          _vm._v(
                            _vm._s(
                              row.payTypes.find(function (v) {
                                return v.dictValue === row.payType
                              }).dictKey
                            )
                          ),
                        ]),
                  ]
                },
              },
            ]),
          }),
          _c("vxe-table-column", {
            attrs: {
              field: "productCode",
              title: "产品名称",
              "min-width": "120",
              "cell-render": !_vm.disabled
                ? {
                    name: "$select",
                    options: _vm.productList,
                    events: { change: _vm.productChange },
                  }
                : {},
            },
            scopedSlots: _vm._u(
              [
                _vm.disabled
                  ? {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [_vm._v(_vm._s(row.productName))]
                      },
                    }
                  : null,
              ],
              null,
              true
            ),
          }),
          _c("vxe-table-column", {
            attrs: {
              field: "productLevelName",
              title: "产品类型",
              "min-width": "120",
            },
          }),
          _c("vxe-table-column", {
            attrs: {
              field: "applyAmount",
              title: "申请金额",
              "min-width": "120",
              "cell-render": !_vm.disabled
                ? {
                    name: "ElInputNumber",
                    props: { min: 0, precision: 2, controls: false },
                  }
                : {},
            },
          }),
          !_vm.disabled
            ? _c("vxe-table-column", {
                attrs: {
                  title: "操作",
                  width: "60",
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c(
                            "div",
                            { staticClass: "setting-btn" },
                            [
                              _c(
                                "el-popconfirm",
                                {
                                  attrs: {
                                    title: "此操作将永久删除该数据？",
                                    "confirm-button-type": "text",
                                  },
                                  on: {
                                    confirm: function ($event) {
                                      return _vm.removeRow(row)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        slot: "reference",
                                        type: "text",
                                      },
                                      slot: "reference",
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-delete",
                                        staticStyle: { color: "#f5222d" },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  247235278
                ),
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }