<script>
import Form, { formCreate } from '../../../../../../components/form';
import UpdateTable from '../upload_modal/upload_table.vue';
import Storage from '../../../../../../utils/storage';
import request from '../../../../../../utils/request';

formCreate.component('UpdateTable', UpdateTable);

export default {
  extends: Form,
  props: {
    defaultValue: Object,
  },
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [
        {
          type: 'input',
          field: 'fullName',
          title: '申请人',
          props: {
            disabled: true,
          },
          col: {
            md: { span: 12 },
          },
        },
        {
          type: 'input',
          field: 'orgName',
          title: '部门',
          props: {
            disabled: true,
          },
          col: {
            md: { span: 12 },
          },
        },
        {
          type: 'select',
          field: 'workFlowKey',
          title: '流程名称',
          required: true,
          on: {
            change: (e) => this.workFlowKeyChange(e),
          },
          col: {
            md: { span: 12 },
          },
        },
        {
          type: 'hidden',
          field: 'workFlowName',
          title: '流程名称',
          col: {
            md: { span: 12 },
          },
        },
        {
          type: 'input',
          field: 'title',
          title: '主题',
          col: {
            md: { span: 12 },
          },
        },
        {
          type: 'input',
          field: 'approveRemarks',
          title: '详情',
          props: {
            type: 'textarea',
          },
          col: {
            md: { span: 24 },
          },
        },
        {
          type: 'UpdateTable',
          field: 'activityFileList',
          title: '附件',
          on: {
            onGetFileList: (e) => this.uploadChange(e),
          },
          col: {
            md: { span: 24 },
          },
        },
      ],
      approveData: [],
    };
  },
  mounted() {
    this.getApprove();

    if (Object.keys(this.defaultValue).length > 0) {
      this.setValue(this.defaultValue);
    } else {
      this.getUserInfo();
    }
  },
  methods: {
    /**
     * 获取用户信息
     */
    getUserInfo() {
      const userInfo = Storage.l.get('userInfo', true);
      this.setValue({
        fullName: userInfo.fullName,
        orgName: userInfo.orgName,
      });
    },
    /**
     * 流程名称change事件
     * @params e 当前选中值
     */
    workFlowKeyChange(e) {
      const workFlowKeyOptions = this.getRule('workFlowKey').options;
      const selected = workFlowKeyOptions.find((item) => item.value === e);
      this.setValue({
        workFlowName: selected.label,
      });
    },
    /**
     * 文件上传change事件
     * @params e 当前组件的值
     */
    uploadChange(e) {
      const list = e.map((item) => ({
        objectName: item.fileName,
        fileAddress: item.url,
      }));

      this.setValue({
        activityFileList: list,
      });
    },
    /**
     * 获取流程
     */
    getApprove() {
      request.post('/activiti/taActBaseController/getSubmitActiviti').then((res) => {
        if (res.success) {
          this.approveData = res.result.details.map((item) => ({
            label: item.processName,
            value: item.processKey,
          }));
          const workFlowKey = this.getRule('workFlowKey');
          workFlowKey.options = this.approveData;
        }
      });
    },
    /**
     * 提交
     */
    submit() {
      const formData = this.getFormData();

      if (formData) {
        const { submitUrl, row } = this.formConfig;

        const rowData = { ...row };

        request.post(submitUrl, {
          ...rowData,
          ...formData,
        }).then((res) => {
          if (res.success) {
            this.$message.success('提交成功！');
            this.$emit('onClose');
            this.$emit('submit');
          }
        });
      }
    },
  },
};
</script>

<style></style>
