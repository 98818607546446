import Form, { formCreate } from '../../../../../../components/form';
import BudgetChoice from './budgetButton.vue';
import Additional from '../additional';

formCreate.component('BudgetChoice', BudgetChoice);
formCreate.component('ActivityAdditional', Additional);

const isEmpty = (value) => {
  if (value == null) {
    // 等同于 value === undefined || value === null
    return true;
  }
  const type = Object.prototype.toString.call(value).slice(8, -1);
  switch (type) {
    case 'String':
      return !value;
    case 'Array':
      return !value.length;
    case 'Object':
      return Object.keys(value).length === 0; // 普通对象使用 for...in 判断，有 key 即为 false
    default:
      return false; // 其他对象均视作非空
  }
};

export default {
  extends: Form,
  props: {
    parentGetField: Function,
    costFormCode: String,
    defaultValue: Object,
  },
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formParentCode: 'CRM20210315000001410',
      // formFunctionCode: this.costFormCode,
      // 按钮
      buttons: {
        submit: false,
        close: false,
      },
    };
  },
  async created() {
    const res = await this.getFormRule(this.costFormCode, this.formParentCode);

    if (!isEmpty(this.defaultValue)) {
      this.$nextTick(() => {
        this.setValue({
          ...this.defaultValue,
        });
      });
    }

    this.$nextTick(() => {
      this.$emit('list', res);
    });
  },
  methods: {
    // 设置rule
    setRule(item) {
      const v = item;

      if (v.field === 'applyAmount') {
        v.props = {
          ...v.props,
          precision: 2,
          controls: false,
        };
      } else if (v.field === 'detailBudgetVos') {
        v.props = {
          ...v.props,
          getField: (field) => this.parentGetField(field),
        };
        v.value = [];
      } else if (v.field === 'attachVos') {
        v.props = {
          ...v.props,
          getField: (field) => this.getFieldValue(field),
        };
        v.value = [];
      } else if (v.field === 'beginDate' || v.field === 'endDate') {
        v.props = {
          ...v.props,
          type: 'datetime',
          relation: {
            beginField: 'beginDate',
            endField: 'endDate',
            gtNow: true,
          },
        };
      }

      return v;
    },
    // 表单渲染完成后回调
    formComplete() {
      const detailBudgetVos = this.getRule('detailBudgetVos');
      detailBudgetVos.on.change = (e) => {
        this.fApi.clearValidateState('detailBudgetVos');
      };
    },
  },
};
