<template>
  <div class="budget-table">
    <div class="budget-head" v-if="type === 'form' && !disabled">
      <div>
        当前已选预算可用余额：<span class="red">总计 {{ countNum }}</span>
      </div>
      <div>
        当前费用申请总额：<span class="red">总计 {{ applyAmount }}</span>
      </div>
      <el-button size="small" @click="openModal">点击选择预算</el-button>
    </div>
    <div class="list">
      <vxe-table border show-overflow ref="xTable" :data="value">
        <vxe-table-column title="费用预算编码" field="feeBudgetCodes" min-width="120"></vxe-table-column>
        <vxe-table-column title="所属费用科目" field="budgetSubjectsName" min-width="120"></vxe-table-column>
        <vxe-table-column title="费用科目编码" field="budgetSubjectsCode" min-width="130"></vxe-table-column>
        <vxe-table-column title="年度" field="budgetYearName" min-width="120"></vxe-table-column>
        <vxe-table-column title="季度" field="budgetQuaterName" min-width="120"></vxe-table-column>
        <vxe-table-column title="组织名称" field="orgName" min-width="120"></vxe-table-column>
        <vxe-table-column title="客户名称" field="customerName" min-width="120"></vxe-table-column>
        <vxe-table-column title="产品名称" field="productName" min-width="120"></vxe-table-column>
        <vxe-table-column :title="type === 'form' ? '可用余额' : '剩余可用金额'" field="canUseAmount" min-width="120"></vxe-table-column>
        <vxe-table-column
          title="申请金额"
          field="applyAmount"
          align="center"
           min-width="120"
          :cell-render="!disabled && type !== 'register' ? {
            name: 'ElInputNumber',
            props: { min: 0, precision: 2, controls: false }
          } : {}"
        ></vxe-table-column>
        <!-- <vxe-table-column title="审批金额" field="approveAmount" min-width="120" v-if="type === 'register'"></vxe-table-column> -->
        <!-- <vxe-table-column title="剩余可用金额" field="canUseAmount" min-width="120" v-if="type === 'register'"></vxe-table-column> -->
        <vxe-table-column v-if="type === 'form' && !disabled" title="操作" width="60" fixed="right" align="center">
          <template v-slot="{ row }">
            <div class="setting-btn">
              <el-popconfirm
                title="此操作将永久删除该数据？"
                confirm-button-type="text"
                @confirm="removeRow(row)"
              >
                <el-button slot="reference" type="text">
                  <i class="el-icon-delete" style="color: #f5222d"></i>
                </el-button>
              </el-popconfirm>
            </div>
          </template>
        </vxe-table-column>
      </vxe-table>
    </div>
    <SelectConfig ref="selectConfig" @onGetSelect="onGetSelect" />
  </div>
</template>

<script>
import SelectConfig from '../../../../../../components/select_org_cus_ter/select_config.vue';

export default {
  name: 'budget',
  props: {
    value: Array,
    disabled: Boolean,
    type: String,
    getField: Function,
  },
  data() {
    return {};
  },
  components: {
    SelectConfig,
  },
  computed: {
    countNum() {
      let count = 0;
      if (this.value) {
        count = this.value.reduce((num, cur) => Number(num) + Number(cur.canUseAmount), 0);
      }

      return count;
    },
    applyAmount() {
      let count = 0;
      if (this.value) {
        count = this.value.reduce((num, cur) => Number(num) + Number(cur.applyAmount), 0);
      }

      return count;
    },
  },
  methods: {
    // 弹框选择列表
    openModal() {
      const beginDate = this.getField('beginDate');
      const endDate = this.getField('endDate');
      const fineCode = this.getField('fineCode');
      const orgCodeList = this.getField('orgCodeList');
      const customerCodeList = this.getField('customerCodeList');

      if (!beginDate) {
        this.$message.info('请选择活动开始时间');
      } else if (!endDate) {
        this.$message.info('请选择活动结束时间');
      } else if (!fineCode) {
        this.$message.info('请选择活动类型');
      } else {
        const params = {
          functionCode: 'tpm_activity_manage_budget_list',
          data: this.value || [],
          selectionList: [],
          idKey: 'feeBudgetCode',
          paramData: {
            beginDate,
            endDate,
            fineCode,
            orgCodeList: orgCodeList.split(',').filter((a) => a),
            customerCodeList,
          },
        };
        this.$refs.selectConfig.openSelectModal(params);
      }
    },
    // 选择数据回调
    onGetSelect(val) {
      // this.countNum = val.reduce((num, cur) => Number(num) + Number(cur.canUseAmount), 0);

      // const list = val.map((item) => {
      //   const v = item;
      //   delete v.id;
      //   return v;
      // });

      this.$emit('input', val);
    },
    // 删除行数据
    removeRow(row) {
      const index = this.$refs.xTable.getRowIndex(row);
      const list = this.value.filter((v, k) => k !== index);
      // this.countNum = list.reduce((num, cur) => Number(num) + Number(cur.canUseAmount), 0);

      this.$emit('input', list);
    },
  },
};
</script>

<style lang="less" scoped>
.budget-table {
  padding-left: 40px;
  box-sizing: border-box;

  .budget-head {
    display: flex;
    align-items: center;
    padding-bottom: 20px;

    .red {
      color: red;
      margin: 0 10px;
    }
  }

  /deep/.el-input__inner {
    padding: 0;
  }
}
</style>
