<template>
  <div class="register" v-if="costFormCode">
    <el-button icon="el-icon-plus" type="text" @click="addTabs" v-if="subFormConfig.code !== 'view' && verifyState">新增明细</el-button>
    <div class="tabs" v-if="verifyState">
      <el-tabs
        :value="activeName"
        :closable="subFormConfig.code !== 'view'"
        @tab-click="handleClick"
        @tab-remove="removeTab"
        v-if="editableTabs.length > 1"
      >
        <el-tab-pane
          v-for="(v, k) in editableTabs"
          :key="k"
          :label="v.title + ' ' + (k + 1)"
          :name="`${k + 1}`"
        ></el-tab-pane>
      </el-tabs>
    </div>
    <div class="register-form">
      <!-- @change="changeFn" -->
      <sysForm ref="sysform" :formConfig="subFormConfig" :parentGetField="parentGetField" :costFormCode='costFormCode' :defaultValue='defaultValue' @list='verifyList'/>
    </div>
  </div>
</template>

<script>
import sysForm from './form';

export default {
  name: 'register',
  props: {
    subFormConfig: Object,
    parentGetField: Function,
    value: Array || String,
    costFormCode: String,
  },
  data() {
    return {
      activeName: '1',
      editableTabs: [
        {
          title: '活动登记表',
          content: null,
        },
      ],
      defaultValue: {},
      verifyState: false,
    };
  },
  watch: {
    value(val) {
      if (val) {
        this.defaultValue = val[0].content;
        this.editableTabs = val;
      }
    },
  },
  components: {
    sysForm,
  },
  methods: {
    // 验证表单配置
    verifyList(list) {
      if (list.length > 0) {
        this.verifyState = true;
      }
    },
    // 添加明细标签页
    addTabs() {
      this.getEditDataFn();

      this.$nextTick(() => {
        this.editableTabs.push({
          title: '活动登记表',
          content: null,
        });
        const len = this.editableTabs.length;
        this.activeName = `${Number(len)}`;
        this.resetForm();
      });
    },
    // 切换标签页
    handleClick(active) {
      this.getEditDataFn();

      this.$nextTick(() => {
        this.activeName = active.name;
        const num = Number(active.name) - 1;
        const item = this.editableTabs[num];
        this.resetForm(item.content);
      });
    },
    // 移除标签页
    removeTab(targetName) {
      const tabs = this.editableTabs.filter((tab, k) => k + 1 !== Number(targetName));

      if (this.activeName === targetName || Number(this.activeName) === this.editableTabs.length) {
        this.activeName = '1';
        this.resetForm(tabs[0].content);
      }

      this.editableTabs = tabs;
    },
    // 给子级表单赋值处理
    resetForm(item) {
      if (item) {
        this.$refs.sysform.setValue(item);
      } else {
        this.$refs.sysform.resetFields();
      }
    },
    // 保存当前子表单编辑数据
    getEditDataFn() {
      const oldNum = Number(this.activeName);
      this.editableTabs[oldNum - 1].content = this.$refs.sysform.getFormData();
      return true;
    },
    // 保存获取数据
    getData() {
      const oldNum = Number(this.activeName);
      const formData = this.$refs.sysform.getFormData();

      if (!formData) {
        return false;
      }
      this.editableTabs[oldNum - 1].content = formData;

      return this.editableTabs;
    },
  },
};
</script>

<style lang="less" scoped>
.register {
  padding-left: 40px;
  box-sizing: border-box;

  &-form {
    margin-top: 20px;
  }

  .tabs {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -khtml-user-select: none;
    user-select: none;
  }
}
</style>
