import request from '../../../../../../utils/request';
import Form, { formCreate } from '../../../../../../components/form';

import TextView from '../text_view';
import Budget from '../budget';
import RegisterButton from '../register_button';

formCreate.component('textView', TextView);
formCreate.component('ActivityBudget', Budget);
formCreate.component('ActivityRegister', RegisterButton);

export default {
  extends: Form,
  props: {
    id: String,
  },
  components: { formCreate: formCreate.$form() },
  data() {
    return {
      formParentCode: 'CRM20210308000001381',
      formFunctionCode: 'tpm_activity_manage_register',
      buttonText: {
        submit: '保存',
      },
      buttons: {
        submitAudit: this.formConfig.code !== 'view',
      },
      // editData: {},
    };
  },
  methods: {
    // 设置rule
    setRule(item) {
      const v = item;
      if (v.field === 'editBudgetVos') {
        v.props = {
          ...v.props,
          type: 'register',
        };
      } else if (v.field === 'detailVos') {
        v.props = {
          ...v.props,
          subFormConfig: {
            code: this.formConfig.code,
          },
          parentGetField: (field) => this.getFieldValue(field),
        };
      }

      return v;
    },
    // 表单渲染完成后回调
    async formComplete() {
      const { code, row } = this.formConfig;
      let url = '/tpm/tpmLiqueurActController/query';

      if (code === 'edit' || code === 'view' || this.id) {
        url = '/tpm/tpmLiqueurActRegisterController/query';
      }

      const res = await request.get(url, { id: row ? row.id : this.id });

      if (res.success) {
        const data = res.result;
        data.activityTime = `${data.beginDate} 至 ${data.endDate}`;
        if (code === 'edit' || code === 'view' || this.id) {
          // this.editData = data;
          data.detailVos = this.resetFn(data.detailVos);
        }
        this.setValue(data);

        this.updateRules({
          detailVos: {
            props: {
              costFormCode: data.costFormCode,
            },
          },
        });
      }
    },
    resetFn(items) {
      const list = items.map((item) => {
        const v = item;

        v.detailBudgetVos = v.detailBudgetVos.map((sub) => ({
          controlId: sub.controlId,
          feeBudgetCodes: sub.feeBudgetCodes,
          budgetSubjectsCode: sub.budgetSubjectsCode,
        }));

        return {
          title: '活动登记表',
          content: v,
        };
      });

      return list;
    },
    // 提交
    submit(e) {
      const formData = this.fApi.method('detailVos', 'getData')(); // 获取表单的值

      if (formData) {
        let url = '/tpm/tpmLiqueurActRegisterController/save';
        let state = false;
        state = formData.some((v) => !v.content);

        if (state) {
          this.$message.error('请填写登记明细');
          return;
        }

        const actCode = this.getFieldValue('actCode');

        const params = {
          actCode,
          saveType: e.toString(),
          detailVos: formData.map((v) => v.content),
        };

        if (this.formConfig.code === 'edit') {
          url = '/tpm/tpmLiqueurActRegisterController/update';

          params.id = this.formConfig.row.id;
          params.saveType = (e + 2).toString();
        }

        if (e === 2) {
          this.$emit('submit', {
            row: Object.assign(this.formConfig.row, params),
            submitUrl: url,
          });
        } else {
          request.post(url, Object.assign(this.formConfig.row, params)).then((res) => {
            if (res.success) {
              this.$message({
                type: 'success',
                message: '操作成功',
              });
              this.$emit('onClose');
              this.$emit('onGetList');
            }
          });
        }
      }
    },
  },
};
