<template>
  <div class="budget-choice">
    <div class="choice">
      <el-button @click="openModal" v-if="!disabled">点击选择费用预算</el-button>
      <div class="tags" :class="{'is-disabled': disabled}">
        <el-tag
          v-for="tag in value"
          :key="tag.feeBudgetCodes"
          :closable="!disabled"
          type="info"
          @close="handleClose(tag)"
        >{{ tag.feeBudgetCodes }}</el-tag>
      </div>
    </div>
    <SelectConfig ref="selectConfig" @onGetSelect="onGetSelect" />
  </div>
</template>

<script>
import SelectConfig from '../../../../../../components/select_org_cus_ter/select_config.vue';

export default {
  name: 'budget-choice',
  props: {
    value: Array,
    getField: Function,
    disabled: Boolean,
  },
  data() {
    return {};
  },
  components: {
    SelectConfig,
  },
  methods: {
    // 弹框选择列表
    openModal() {
      const actCode = this.getField('actCode');

      const params = {
        parentCode: 'CRM20210308000001381',
        functionCode: 'tpm_activity_register_budget_list',
        data: this.value || [],
        selectionList: [],
        idKey: 'feeBudgetCodes',
        paramData: {
          actCode,
        },
      };
      this.$refs.selectConfig.openSelectModal(params);
    },
    // 选择数据回调
    onGetSelect(val) {
      const list = val.map((v) => ({
        controlId: v.id,
        feeBudgetCodes: v.feeBudgetCodes,
        budgetSubjectsCode: v.budgetSubjectsCode,
      }));
      this.$emit('input', list);
      this.$emit('change');
    },
    // 删除标签
    handleClose(tag) {
      const data = this.value.filter(
        (v) => v.feeBudgetCodes !== tag.feeBudgetCodes,
      );
      this.$emit('input', data);
    },
  },
};
</script>

<style lang="less" scoped>
.choice {
  display: flex;

  .tags {
    min-width: 300px;
    min-height: 32px;
    margin-left: 30px;
    border: 1px solid #d7d7d7;
    padding: 0 10px 0 0;
    border-radius: 6px;

    .el-tag {
      margin-left: 10px;
    }

    &.is-disabled {
      background-color: #f5f7fa;
      border-color: #e4e7ed;
      color: #c0c4cc;
      cursor: not-allowed;
    }
  }
}
</style>
