var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "budget-choice" },
    [
      _c(
        "div",
        { staticClass: "choice" },
        [
          !_vm.disabled
            ? _c("el-button", { on: { click: _vm.openModal } }, [
                _vm._v("点击选择费用预算"),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "tags", class: { "is-disabled": _vm.disabled } },
            _vm._l(_vm.value, function (tag) {
              return _c(
                "el-tag",
                {
                  key: tag.feeBudgetCodes,
                  attrs: { closable: !_vm.disabled, type: "info" },
                  on: {
                    close: function ($event) {
                      return _vm.handleClose(tag)
                    },
                  },
                },
                [_vm._v(_vm._s(tag.feeBudgetCodes))]
              )
            }),
            1
          ),
        ],
        1
      ),
      _c("SelectConfig", {
        ref: "selectConfig",
        on: { onGetSelect: _vm.onGetSelect },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }