<template>
  <div class="sub-table">
    <vxe-toolbar v-if="!disabled">
      <template v-slot:buttons>
        <el-button type="text" icon="el-icon-plus" @click="addTable">新增行</el-button>
        <el-button type="text" icon="el-icon-delete" @click="deleteTable" style="color: #f56c6c">清空</el-button>
      </template>
    </vxe-toolbar>
    <vxe-table :data="value" ref="xTable" max-height="200">
      <vxe-table-column type="seq" title="行编号" width="60"></vxe-table-column>
      <vxe-table-column field="categoriesName" title="投入类型名称" min-width="120">
        <template #default="{ row, rowIndex }">
          <el-select
            v-model="row.categoriesName"
            placeholder="请选择"
            :loading="loading"
            @focus="focusEvent"
            @change="v => changeEvent(v, rowIndex)"
            v-if="!disabled"
          >
            <el-option
              v-for="item in tpmCostTypeList"
              :key="item.id"
              :label="item.categoriesName"
              :value="item.categoriesName"
            ></el-option>
          </el-select>
          <span v-else>{{row.categoriesName}}</span>
        </template>
      </vxe-table-column>
      <vxe-table-column field="categoriesCode" title="投入类型编码" min-width="120"></vxe-table-column>
      <vxe-table-column field="ownedSalesCompanyName" title="所属销售公司" min-width="120"></vxe-table-column>
      <vxe-table-column field="ownedCusCodesName" title="所属客户" min-width="120"></vxe-table-column>
      <vxe-table-column field="payType" title="支付类型" min-width="120">
        <template #default="{ row, rowIndex }">
          <el-select
            v-model="row.payType"
            placeholder="请选择"
            v-if="!disabled"
            @change="(v) => payTypeChange(rowIndex, v, row.payTypes)"
          >
            <el-option
              v-for="item in row.payTypes"
              :key="item.id"
              :label="item.dictKey"
              :value="item.dictValue"
            ></el-option>
          </el-select>
          <span v-else>{{row.payTypes.find(v => v.dictValue === row.payType).dictKey}}</span>
        </template>
      </vxe-table-column>
      <!-- <vxe-table-column field="attributionCusCode" title="归属客户名称" min-width="120">
        <template #default="{ row }">
          <el-select v-model="row.attributionCusCode" placeholder="请选择" v-if="!disabled">
            <el-option
              v-for="item in customerList"
              :key="item.id"
              :label="item.customerName"
              :value="item.customerCode"
            ></el-option>
          </el-select>
          <span
            v-else
          >{{textFn(row.attributionCusCode, customerList, 'customerCode', 'customerName')}}</span>
        </template>
      </vxe-table-column>
      <vxe-table-column field="attributionQuarter" title="归属季度" min-width="120">
        <template #default="{ row, rowIndex }">
          <el-select v-model="row.attributionQuarter" placeholder="请选择" v-if="!disabled" @change="(v) => quarterChange(v, rowIndex)">
            <el-option
              v-for="item in dictCodeList.fiscalQuater"
              :key="item.id"
              :label="item.dictValue"
              :value="item.dictCode"
            ></el-option>
          </el-select>
          <span
            v-else
          >{{textFn(row.attributionQuarter, dictCodeList.fiscalMonth, 'dictCode', 'dictValue')}}</span>
        </template>
      </vxe-table-column>
      <vxe-table-column field="attributionMonth" title="归属月份" min-width="120">
        <template #default="{ row }">
          <el-select v-model="row.attributionMonth" placeholder="请选择" v-if="!disabled" :disabled='!row.attributionQuarter'>
            <el-option
              v-for="item in dictCodeList.fiscalMonth"
              :key="item.id"
              :label="item.dictValue"
              :value="item.dictCode"
            ></el-option>
          </el-select>
          <span
            v-else
          >{{textFn(row.attributionMonth, dictCodeList.fiscalMonth, 'dictCode', 'dictValue')}}</span>
        </template>
      </vxe-table-column> -->
      <vxe-table-column
        field="productCode"
        title="产品名称"
        min-width="120"
        :cell-render="!disabled ? {
          name: '$select',
          options: productList,
          events: { change: productChange }
        } : {}"
      >
      <template #default="{ row }" v-if="disabled">{{row.productName}}</template>
      </vxe-table-column>
      <vxe-table-column field="productLevelName" title="产品类型" min-width="120"></vxe-table-column>
      <!-- <vxe-table-column field="unitName" title="单位" min-width="120"></vxe-table-column>
      <vxe-table-column
        field="price"
        title="单价"
        min-width="120"
        :cell-render="!disabled ? {
          name: 'ElInputNumber',
          props: { min: 0, precision: 2, controls: false }
        } : {}"
      ></vxe-table-column>
      <vxe-table-column
        field="applyNumber"
        title="申请数量"
        min-width="120"
        :cell-render="!disabled ? {
          name: 'ElInputNumber',
          props: { min: 0, precision: 0, controls: false }
        } : {}"
      ></vxe-table-column> -->
      <vxe-table-column
        field="applyAmount"
        title="申请金额"
        min-width="120"
        :cell-render="!disabled ? {
          name: 'ElInputNumber',
          props: { min: 0, precision: 2, controls: false }
        } : {}"
      ></vxe-table-column>
      <!-- <vxe-table-column field="approvalNumber" title="审批数量" min-width="120"></vxe-table-column>
      <vxe-table-column field="approvalAmount" title="审批金额" min-width="120"></vxe-table-column>-->
      <!-- <vxe-table-column
        field="payMan"
        title="支付人"
        min-width="120"
        :cell-render="!disabled ? {
          name: 'ElInput'
        } : {}"
      ></vxe-table-column>
      <vxe-table-column
        field="accountBank"
        title="开户行"
        min-width="120"
        :cell-render="!disabled ? {
          name: 'ElInput'
        } : {}"
      ></vxe-table-column>
      <vxe-table-column
        field="account"
        title="账号"
        min-width="120"
        :cell-render="!disabled ? {
          name: 'ElInputNumber',
          props: { min: 0, precision: 0, controls: false }
        } : {}"
      ></vxe-table-column> -->
      <vxe-table-column v-if="!disabled" title="操作" width="60" fixed="right" align="center">
        <template v-slot="{ row }">
          <div class="setting-btn">
            <el-popconfirm
              title="此操作将永久删除该数据？"
              confirm-button-type="text"
              @confirm="removeRow(row)"
            >
              <el-button slot="reference" type="text">
                <i class="el-icon-delete" style="color: #f5222d"></i>
              </el-button>
            </el-popconfirm>
          </div>
        </template>
      </vxe-table-column>
    </vxe-table>
  </div>
</template>

<script>
import request from '../../../../../../utils/request';

export default {
  name: 'additional',
  props: {
    value: Array,
    disabled: Boolean,
    getField: Function,
  },
  data() {
    return {
      tpmCostTypeList: [],
      dictCodeList: {
        fiscalMonth: [],
        fiscalQuater: [],
      },
      loading: true,
      productList: [],
      customerList: [],
    };
  },
  methods: {
    textFn(val, list, code, name) {
      if (val && list.length > 0) {
        const item = list.find((v) => v[code] === val);
        return item ? item[name] : '';
      }
      return '';
    },
    // 请求投入类型图标
    focusEvent() {
      this.loading = true;
      this.getCastType();
    },
    // 监听投入类型改变
    changeEvent(val, index) {
      const item = this.tpmCostTypeList.filter((v) => v.categoriesName === val);
      const list = this.value.map((subItem, k) => {
        const v = subItem;
        if (k === index) {
          v.categoriesCode = item[0].categoriesCode;
          v.ownedSalesCompany = item[0].ownedSalesCompany;
          v.ownedSalesCompanyName = item[0].ownedSalesCompanyDesc;
          v.ownedCusCodes = item[0].ownedCusCode;
          v.ownedCusCodesName = item[0].ownedCusName;
          v.payType = '';
          v.payTypes = item[0].payTypes;
        }

        return v;
      });

      this.$emit('input', list);
    },
    // 监听支付类型
    payTypeChange(index, val, options) {
      const item = options.filter((v) => v.dictValue === val);
      console.log(item);
      const list = this.value.map((subItem, k) => {
        const v = subItem;
        if (k === index) {
          v.payTypeName = item[0].dictKey;
        }

        return v;
      });

      this.$emit('input', list);
    },
    // 监听投入类型改变
    productChange(row, val) {
      const item = this.productList.filter((v) => v.productCode === val.value);
      const list = this.value.map((subItem, k) => {
        const v = subItem;
        if (k === row.rowIndex) {
          v.productName = item[0].productName;
          v.productLevelCode = item[0].productLevelCode;
          v.productLevelName = item[0].productLevelName;
          v.unit = item[0].baseUnit;
          v.unitName = item[0].baseUnitName;
        }

        return v;
      });

      this.$emit('input', list);
    },
    getCastType() {
      const detailBudgetVos = this.getField('detailBudgetVos');
      let budgetSubjectsCodes = [];

      if (detailBudgetVos) {
        budgetSubjectsCodes = detailBudgetVos.map((v) => v.budgetSubjectsCode);
      }
      // 获取投入类型下拉数据
      request
        .post(
          '/tpm/tpmLiqueurActRegisterController/findCategoriesBySubjectCodes',
          {
            budgetSubjectsCodes,
            pageNum: 1,
            pageSize: -1,
          },
        )
        .then((res) => {
          this.loading = false;
          if (res.success) {
            this.tpmCostTypeList = res.result.data.map((v) => ({
              ...v,
              value: v.categoriesCode,
              label: v.categoriesName,
            }));
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 弹框选择列表
    addTable() {
      const detailBudgetVos = this.getField('detailBudgetVos');

      if (!detailBudgetVos || detailBudgetVos.length === 0) {
        this.$message.error('请选择关联预算');
      } else {
        const list = this.value ? this.value.concat() : [];
        list.push({
          categoriesName: '',
          categoriesCode: '',
          ownedCusCodes: '',
          payType: '',
          attributionCusCode: '',
          attributionMonth: '',
          attributionQuarter: '',
          productCode: '',
          productType: '',
          unit: '',
          price: '',
          applyNumber: '',
          applyAmount: '',
          payMan: '',
          accountBank: '',
          account: '',
          payTpyeName: '',
        });
        this.$emit('input', list);
      }
    },
    // 清空表格数据
    deleteTable() {
      this.$confirm('此操作将永久删除当前表格数据，是否继续?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning',
      }).then(() => {
        this.$emit('input', []);
      });
    },
    // 删除行数据
    removeRow(row) {
      const index = this.$refs.xTable.getRowIndex(row);
      const list = this.value.filter((item, k) => index !== k);
      this.$emit('input', list);
    },
    init() {
      // 数据字典下拉数据
      request
        .post('/mdm/mdmdictdata/batchDictSelect', [
          'fiscal_month',
          'fiscal_quater',
        ])
        .then((res) => {
          if (res.success) {
            const data = res.result;

            this.dictCodeList = {
              fiscalMonth: data.fiscal_month.map((v) => ({
                ...v,
                value: v.dictCode,
                label: v.dictValue,
              })),
              fiscalQuater: data.fiscal_quater.map((v) => ({
                ...v,
                value: v.dictCode,
                label: v.dictValue,
              })),
            };
          }
        });
      // 商品列表
      request
        .post('/mdm/mdmProductController/pageList', {
          pageNum: 1,
          pageSize: -1,
        })
        .then((res) => {
          if (res.success) {
            this.productList = res.result.data.map((v) => ({
              ...v,
              value: v.productCode,
              label: v.productName,
            }));
          }
        });
      // 客户列表
      request
        .post('/mdm/mdmCustomerMsgController/pageList', {
          pageNum: 1,
          pageSize: -1,
        })
        .then((res) => {
          if (res.success) {
            this.customerList = res.result.data.map((v) => ({
              ...v,
              value: v.customerCode,
              label: v.customerName,
            }));
          }
        });
    },
    // 季度监听
    quarterChange(v, index) {
      const list = JSON.parse(JSON.stringify(this.value));
      list[index].attributionMonth = '';

      this.$emit('input', list);
    },
  },
  created() {
    this.init();
  },
};
</script>

<style lang="less" scoped>
.sub-table {
  padding-left: 40px;
  box-sizing: border-box;
}
</style>
