<template>
  <div>
    <vxe-toolbar>
      <template v-slot:buttons>
        <el-upload
          class="upload-table"
          v-if="!disabled"
          action="/upload/uploadController/uploadOssList"
          :headers="{
            loginUserToken: Storage.l.get('TokenKey'),
          }"
          multiple
          :show-file-list="false"
          :on-exceed="handleExceed"
          :before-upload="beforeUpload"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :before-remove="beforeRemove"
          :on-success="handleSuccess"
          :limit="limit"
          :accept="accept || ''"
          :file-list="fileList"
        >
          <vxe-button>上传附件</vxe-button>
        </el-upload>
      </template>
    </vxe-toolbar>
    <vxe-table ref="uploadTable" :data="value">
      <vxe-table-column field="objectName" title="附件名称"> </vxe-table-column>
      <vxe-table-column title="操作" width="200" show-overflow>
        <template v-slot="{ row }">
          <el-button type="text" size="mini" @click="downLoad(row)"
            >下载</el-button
          >
          &nbsp;&nbsp;
          <el-button
            type="text"
            size="mini"
            v-if="!disabled"
            @click="handleRemove(row)"
            >删除</el-button
          >
        </template>
      </vxe-table-column>
    </vxe-table>
  </div>
</template>

<script>
import Storage from '../../../../../../utils/storage';

export default {
  name: 'UploadModal',
  props: {
    disabled: Boolean,
    limit: Number,
    accept: String,
    value: Array,
  },
  watch: {},
  data() {
    return {
      Storage,
      modalConfig: {
        title: '选择',
      },
      fileList: [],
      isUpload: false,
    };
  },
  created() {},
  methods: {
    downLoad(row) {
      const file = new XMLHttpRequest();
      file.open('GET', row.url, true);
      file.responseType = 'blob';
      file.onload = () => {
        const content = file.response;
        const link = document.createElement('a');
        link.download = row.fileName;
        link.style.display = 'none';
        const blob = new Blob([content]);
        link.href = URL.createObjectURL(blob);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(link.href);
      };
      file.send();
    },
    beforeUpload(files) {
      const { name } = files;
      let fileType = name.split('.')[name.split('.').length - 1];
      fileType = fileType.toLowerCase();
      if (this.accept && this.accept.indexOf(fileType) === -1) {
        this.isUpload = true;
        this.$message.warning(
          `当前限制选择 ${this.accept} 类型件，本次选择了错误文件`,
        );
        return false;
      }
      return true;
    },
    handleRemove(file) {
      const list = this.value.filter((v) => v.url !== file.url);
      this.$emit('onGetFileList', list);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleSuccess(res) {
      this.isUpload = false;

      if (res.code === 200 && res.success) {
        const list = JSON.parse(JSON.stringify(this.value || []));
        list.push(...res.result);

        this.$emit('onGetFileList', list);
      }
    },
    handleExceed(files, fileList) {
      console.log(files, fileList);
    },
    beforeRemove(file) {
      if (!this.isUpload) {
        return this.$confirm(`确定移除 ${file.name}？`);
      }
      this.isUpload = false;

      return true;
    },
  },
};
</script>
