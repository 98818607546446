var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.costFormCode
    ? _c(
        "div",
        { staticClass: "register" },
        [
          _vm.subFormConfig.code !== "view" && _vm.verifyState
            ? _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-plus", type: "text" },
                  on: { click: _vm.addTabs },
                },
                [_vm._v("新增明细")]
              )
            : _vm._e(),
          _vm.verifyState
            ? _c(
                "div",
                { staticClass: "tabs" },
                [
                  _vm.editableTabs.length > 1
                    ? _c(
                        "el-tabs",
                        {
                          attrs: {
                            value: _vm.activeName,
                            closable: _vm.subFormConfig.code !== "view",
                          },
                          on: {
                            "tab-click": _vm.handleClick,
                            "tab-remove": _vm.removeTab,
                          },
                        },
                        _vm._l(_vm.editableTabs, function (v, k) {
                          return _c("el-tab-pane", {
                            key: k,
                            attrs: {
                              label: v.title + " " + (k + 1),
                              name: "" + (k + 1),
                            },
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "register-form" },
            [
              _c("sysForm", {
                ref: "sysform",
                attrs: {
                  formConfig: _vm.subFormConfig,
                  parentGetField: _vm.parentGetField,
                  costFormCode: _vm.costFormCode,
                  defaultValue: _vm.defaultValue,
                },
                on: { list: _vm.verifyList },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }